import { defineStore } from 'pinia'

import type { TenantHeardAboutUs, TenantOfferGroupsExtendedTableSchema, UpdateTenant } from '@refloat/supabase'
import { useToast } from 'vue-toastification'
import type { Tenant, UpdateTenantOfferGroup } from '@/types'
import { tenantRepository } from '@/repositories'

export const useTenantStore = defineStore('tenant', () => {
  const toast = useToast()
  const { $toastOptions } = useNuxtApp()

  const supabaseUser = useSupabaseUser()
  const userActiveTenant = ref<Tenant | null>(null)
  const userActiveTenantLoading = ref<boolean>(false)
  const tenantOfferGroups = ref<TenantOfferGroupsExtendedTableSchema[] | null>(null)
  const tenantList = ref<Tenant[] | null>(null)
  const tenantListLoading = ref<boolean>(false)

  const {
    getTenant: getTenantRepository,
    getAllTenants: getAllTenantsRepository,
    startTenantStripeDataSync: startTenantStripeDataSyncRepository,
    updateTenant: updateTenantRepository,
    getTenantOfferGroups: getTenantOfferGroupsRepository,
    setTenantOfferGroup: updateTenantOfferGroupRepository,
  } = tenantRepository()

  async function getTenant() {
    if (supabaseUser.value?.id) {
      userActiveTenantLoading.value = true
      const { data, error } = (await getTenantRepository()).value

      if (error) {
        // eslint-disable-next-line  no-console
        console.log(
          `ERROR: Failed to retrieve tenant for the user with an id ${supabaseUser.value!.id}: ${error?.message}`,
        )
      }
      if (data) {
        userActiveTenant.value = data
        userActiveTenantLoading.value = false
        return userActiveTenant.value
      }
    }
    else {
      // eslint-disable-next-line  no-console
      console.log('ERROR: User is not logged in')
    }
  }

  async function getAllTenants() {
    if (supabaseUser.value?.id && supabaseUser.value?.app_metadata?.role === 'admin') {
      tenantListLoading.value = true
      const { data, error } = (await getAllTenantsRepository()).value

      if (error) {
        // eslint-disable-next-line  no-console
        console.log(
          `ERROR: Failed to retrieve list of tenants : ${error?.message}`,
        )
      }

      if (data) {
        tenantList.value = data
        tenantListLoading.value = false
        return tenantList.value
      }
    }
    else {
      // eslint-disable-next-line  no-console
      console.log('ERROR: User is not logged in')
    }
  }

  async function resetTenantList() {
    tenantList.value = null
    tenantListLoading.value = false
  }

  async function startTenantStripeDataSync(
    chosenTenantId: string,
    chosenTenantCompanyName: string,
  ) {
    if (supabaseUser.value?.id && supabaseUser.value?.app_metadata?.role === 'admin') {
      const data = await startTenantStripeDataSyncRepository(
        chosenTenantId,
      )

      if (data.value.error) {
        // eslint-disable-next-line  no-console
        console.log(
          `ERROR: Failed to start tenant stripe data sync for tenant with an id ${userActiveTenant.value!.id}: ${data.value.error?.message}`,
        )
        toast.error(`Failed to start tenant stripe data sync for tenant: "${chosenTenantCompanyName}"\n${data.value.error.message}!`, $toastOptions)
      }
      if (data.value.data) {
        await getAllTenants()
        toast.success(`Tenant stripe data sync started for tenant: ${chosenTenantCompanyName}!`, $toastOptions)
      }
    }
    else {
      // eslint-disable-next-line  no-console
      console.log('ERROR: Tenant does not exist for the user')
    }
  }

  async function getTenantOfferGroups() {
    if (supabaseUser.value?.id) {
      const { data, error } = (await getTenantOfferGroupsRepository()).value

      if (error) {
        // eslint-disable-next-line  no-console
        console.log(
          `ERROR: Failed to retrieve tenant offer groups: ${error?.message}`,
        )
      }
      if (data) {
        tenantOfferGroups.value = data
        return tenantOfferGroups.value
      }
    }
    else {
      // eslint-disable-next-line  no-console
      console.log('ERROR: User is not logged in')
    }
  }

  async function updateTenantInfo(tenantInfo: Partial<UpdateTenant> & TenantHeardAboutUs) {
    if (userActiveTenant.value?.id) {
      const { data, error } = (await updateTenantRepository(userActiveTenant.value!.id, tenantInfo)).value

      if (error) {
        // eslint-disable-next-line  no-console
        console.log(
          `ERROR: Failed to update tenant with an id ${userActiveTenant.value!.id}: ${error?.message}`,
        )
      }
      if (data) {
        userActiveTenant.value = data
        return userActiveTenant.value
      }
    }
    else {
      // eslint-disable-next-line  no-console
      console.log('ERROR: Tenant does not exist for the user')
    }
  }

  async function updateTenantOfferGroup(tenantOfferGroup: UpdateTenantOfferGroup) {
    if (userActiveTenant.value?.id) {
      const { data, error } = (
        await updateTenantOfferGroupRepository(
          userActiveTenant.value!.id,
          tenantOfferGroup,
        )
      ).value

      if (error) {
        // eslint-disable-next-line  no-console
        console.log(
          `ERROR: Failed to update tenant offer group | tenant: ${userActiveTenant.value!.id}, offer group: ${tenantOfferGroup.tenant_offer_group_id} | error: ${error?.message}`,
        )
      }
      if (data) {
        userActiveTenant.value = data
        return userActiveTenant.value
      }
    }
    else {
      // eslint-disable-next-line  no-console
      console.log('ERROR: Tenant does not exist for the user')
    }
  }

  return {
    getTenant,
    getAllTenants,
    updateTenantInfo,
    getTenantOfferGroups,
    updateTenantOfferGroup,
    resetTenantList,
    startTenantStripeDataSync,
    userActiveTenant,
    userActiveTenantLoading,
    tenantList,
    tenantListLoading,
    tenantOfferGroups,
  }
})
