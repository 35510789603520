import { cloneDeep } from 'lodash'
import { getActivePinia } from 'pinia'
import type { Pinia, Store } from 'pinia'

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>
}

export default defineNuxtPlugin(async (nuxtApp) => {
  const pinia = getActivePinia()! as ExtendedPinia

  pinia.use(({ store }) => {
    const initialState = cloneDeep(store.$state)

    store.$reset = () => {
      store.$patch(($state) => {
        Object.assign($state, initialState)
      })
    }
  })

  function resetAppState() {
    if (!pinia)
      throw new Error('There is no stores')

    const resetStores: Record<string, () => void> = {}

    pinia._s.forEach((store, name) => {
      resetStores[name] = () => store.$reset()
    })

    resetStores.all = () => pinia._s.forEach(store => store.$reset())

    resetStores.all()
  }

  nuxtApp.vueApp.provide('resetAppState', resetAppState)

  return {
    provide: {
      resetAppState,
    },
  }
})
