import Toast, { POSITION } from 'vue-toastification'
import type { PluginOptions } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

export default defineNuxtPlugin(async (nuxtApp) => {
  const options: PluginOptions = {
    position: POSITION.TOP_CENTER,
    timeout: 5000,
    closeOnClick: false,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: false,
    showCloseButtonOnHover: false,
    closeButton: false,
    hideProgressBar: true,
    icon: true,
  }

  nuxtApp.vueApp.use(Toast)
  nuxtApp.vueApp.provide('toastPptions', options)

  return {
    provide: {
      toastOptions: options,
    },
  }
})
