<script setup lang="ts">
import type { RouteLocationNormalizedLoaded } from 'vue-router'

import 'posthog-js/dist/recorder'
import posthog from 'posthog-js'
import * as Sentry from '@sentry/vue'
import { Intercom } from '@intercom/messenger-js-sdk'

const supabaseClient = useSupabaseClient()
const supabaseUser = useSupabaseUser()
const { resetAppState } = useResetStores()
const route = useRoute()
const config = useRuntimeConfig()
const { $router, vueApp } = useNuxtApp()

const userTenant = useTenantStore()
const { userActiveTenant } = storeToRefs(userTenant)

useHead({
  title: 'Refloat platform',
  bodyAttrs: {
    class: 'bg-gray-50 font-manrope',
  },
})

//  required '@headlessui/vue' (Tailwind UI)
//  in SSR context/mode
provideHeadlessUseId(() => useId())

watchEffect(() => {
  if (supabaseUser.value && !isUserAccessingAuthProtectedRoute(route as RouteLocationNormalizedLoaded))
    $router.push({ path: '/dashboard' })

  if (!supabaseUser.value && isUserAccessingAuthProtectedRoute(route as RouteLocationNormalizedLoaded))
    $router.push({ path: '/login' })
})

function isUserAccessingAuthProtectedRoute(
  route: RouteLocationNormalizedLoaded,
) {
  return !['login', 'register', 'forgot-password'].includes(route.name as string)
}

function getRandomString() {
  return Number.parseInt(Math.random().toString().substring(2)).toString(16)
}

supabaseClient.auth.onAuthStateChange(async (event) => {
  if (event === 'SIGNED_OUT')
    resetAppState()
})

//  Posthog session recording
//  we want to initialize the session recording, even if the user is not logged in
//  so we can track the user's behavior before they log in (on auth pages)
posthog.init(
  config.public.posthogSessionRecordingApiKey as string,
  {
    api_host: 'https://eu.i.posthog.com',
    persistence: 'memory',
    person_profiles: 'always',
    disable_persistence: true,
    bootstrap: {
      sessionID: Array.from({ length: 3 }).fill(getRandomString()).join(''),
    },
    disable_session_recording: false,
    session_recording: {
      maskAllInputs: false,
    },
  },
)

Sentry.init({
  app: vueApp,
  dsn: config.public.sentryPortalDsn,
  tracesSampleRate: 1.0,
  // Capture Replays only for sessions with an error
  replaysOnErrorSampleRate: 1.0,
})

watch(userActiveTenant, async () => {
  if (userActiveTenant.value) {
    posthog.identify(supabaseUser.value?.id, {
      personProperties: {
        user_id: supabaseUser.value?.id,
        tenant_id: userActiveTenant.value?.id,
      },
      groups: {
        tenant: userActiveTenant.value?.id,
      },
    })

    Intercom({
      api_base: 'https://api-iam.eu.intercom.io',
      app_id: config.public.intercomAppId,
      user_id: userActiveTenant.value.user,
      user_hash: userActiveTenant.value.intercom_user_hash,
      name: supabaseUser.value!.email,
      email: supabaseUser.value!.email,
      created_at: Math.round(
        new Date(supabaseUser.value!.created_at).getTime() / 1000,
      ),
    })
  }
}, { immediate: true })

onDeactivated(() => {
  posthog.stopSessionRecording()
  posthog.reset(true)
})
</script>

<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="css">
html,
body,
#__nuxt,
#__layout {
  @apply h-full w-full;
}
</style>
