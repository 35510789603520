import unhead_neSs9z3UJp from "/opt/render/project/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_qb1L118OuS from "/opt/render/project/src/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import check_outdated_build_client_LIYcCMJD18 from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_xKuw1km5sV from "/opt/render/project/src/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/portal/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_kpnqASteq8 from "/opt/render/project/src/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import pwa_icons_Z9m1jdsncU from "/opt/render/project/src/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_V4UTG2qgGR from "/opt/render/project/src/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_client_VfWFs4sWWP from "/opt/render/project/src/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import unocss_MzCDxu9LMj from "/opt/render/project/src/apps/portal/.nuxt/unocss.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _00_sentry_XEkpcNXOPN from "/opt/render/project/src/apps/portal/src/plugins/00.sentry.ts";
import _01_api_qHtYXWpppy from "/opt/render/project/src/apps/portal/src/plugins/01.api.ts";
import _02_store_cKx1PV6XVH from "/opt/render/project/src/apps/portal/src/plugins/02.store.ts";
import _03_toasts_messages_KlwY4RNQJ9 from "/opt/render/project/src/apps/portal/src/plugins/03.toasts-messages.ts";
export default [
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  supabase_client_qb1L118OuS,
  check_outdated_build_client_LIYcCMJD18,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_kpnqASteq8,
  pwa_icons_Z9m1jdsncU,
  pwa_client_V4UTG2qgGR,
  plugin_client_VfWFs4sWWP,
  unocss_MzCDxu9LMj,
  chunk_reload_client_SeG0EjL5Ec,
  _00_sentry_XEkpcNXOPN,
  _01_api_qHtYXWpppy,
  _02_store_cKx1PV6XVH,
  _03_toasts_messages_KlwY4RNQJ9
]